function setMainMargin() {
  const mainElement = document.querySelector('.main');
  const footerElement = document.querySelector('footer');
  
  if (mainElement && footerElement) {
    const footerHeight = footerElement.offsetHeight;
  
    const footerStyles = getComputedStyle(footerElement);
    const variableSpacing = parseFloat(footerStyles.getPropertyValue('--header-size')) || 0;
  
    const totalSpacing = footerHeight + variableSpacing;
 
    mainElement.style.marginBottom = `${totalSpacing}px`;
  }
}
  
  // Initial setup
setMainMargin();
  
  // Update margin on window resize
window.addEventListener('resize', setMainMargin);
