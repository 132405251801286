document.addEventListener('DOMContentLoaded', function () {
    // Add smooth scrolling to all links
    document.querySelectorAll('a[href^="#"]').forEach(anchor => {
        anchor.addEventListener('click', function (event) {
            // Make sure the hash has a value
            if (this.hash !== "") {
                // Prevent default anchor click behavior
                event.preventDefault();

                // Store the hash
                const hash = this.hash;

                // Find the target element
                const targetElement = document.querySelector(hash);

                if (targetElement) {
                    // Scroll smoothly to the target element
                    targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });

                    // Update the URL hash after scrolling
                    setTimeout(() => {
                        window.location.hash = hash;
                    }, 800); // Match the animation duration
                }
            }
        });
    });
});
